import { ReactComponent as MBWay } from 'assets/images/logos/Logo_MBWay.svg'
import { ReactComponent as AmexCard } from 'assets/images/icons/AmexCard.svg'
import { ReactComponent as VisaCard } from 'assets/images/icons/VisaCard.svg'
import { ReactComponent as MasterCard } from 'assets/images/icons/MasterCard.svg'

export const routes = [
  {
    path: "/access-lockers",
    inativityTime: false
  },
  {
    path: "/assigned-locker",
    inativityTime: 10
  },
  {
    path: "/checkout",
    inativityTime: 10
  },
  {
    path: "/checkout-summary",
    inativityTime: false
  },
  {
    path: "/collect-number",
    inativityTime: false
  },
  {
    path: "/create-pin",
    inativityTime: false
  },
  {
    path: "/error",
    inativityTime: false
  },
  {
    path: "/home",
    inativityTime: false
  },
  {
    path: "/invoice",
    inativityTime: false
  },
  {
    path: "/open-locker",
    inativityTime: 10
  },
  {
    path: "/payment",
    inativityTime: false
  },
  {
    path: "/payment/credit-card",
    inativityTime: false
  },
  {
    path: "/payment/mbway",
    inativityTime: false
  },
  {
    path: "/prices",
    inativityTime: false
  },
  {
    path: "/recover-pin",
    inativityTime: 10
  },
  {
    path: "/release-locker",
    inativityTime: 10
  },
  {
    path: "/error",
    inativityTime: 10
  },
  {
    path: "/service-down",
    inativityTime: 10
  },
  {
    path: "/closed",
    inativityTime: 10
  },
  {
    path: "/system-down",
    inativityTime: 10
  },
  {
    path: "/confirm-rental",
    inativityTime: false
  }
];

export const modals = [
  {
    name: "language",
    inativityTime: 10
  },
  {
    name: "faqs",
    inativityTime: 10
  },
  {
    name: "notifications",
    inativityTime: 10
  }
]


export const paymentMethods = [
  {
    method: "credit_card",
    name: "creditCard",
    icons: [
      <VisaCard className='p-0 h-auto' style={{ width: 40, margin: "0 1.5px 0 0" }} />,
      <MasterCard className='p-0 h-auto' style={{ width: 40, margin: "0 1.5px" }} />,
      <AmexCard className='p-0 h-auto' style={{ width: 40, margin: "0 0 0 1.5px" }} />
    ]
  },
  {
    method: "mbway",
    name: "MBWay",
    icons: [<MBWay className='m-0 p-0 h-auto' style={{ width: 55 }} />]
  }
]

export const pathnameSocketFail = ['/terms-conditions', '/prices', '/create-pin', '/invoice', '/payment']

export const pathnamePaymentMissing = ['/terms-conditions', '/prices', '/create-pin', '/invoice', '/payment']

export const pathnameCheckScheduleAndOffline = ['/terms-conditions', '/prices', '/create-pin', '/invoice', '/payment']